import { Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-button-outlined',
  standalone: true,
  templateUrl: './button-outlined.component.html',
  styleUrl: './button-outlined.component.scss',
  imports: [RouterModule]
})
export class ButtonOutlinedComponent {

  @Input() text!: string
  @Input() router!: string
  @Input() imageSrc?: string
  
}
