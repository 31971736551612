import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { Router, RouterModule } from '@angular/router';

@Component({
  selector: 'app-button-brand',
  standalone: true,
  templateUrl: './button-brand.component.html',
  styleUrl: './button-brand.component.scss',
  imports: [RouterModule]
})
export class ButtonBrandComponent {

  constructor(private Router: Router) {}

  @Input() text!: string
  @Input() imageSrc?: string
  @Input() type?: 'submit'
  @Input() disabled?: boolean
  @Output() onClick = new EventEmitter<Event>();
  

  @HostListener('click', ['$event'])
  handleClick(event: Event) {
    this.onClick.emit(event);
  }


}
